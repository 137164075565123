//******************************************************************************
// Properties
//******************************************************************************
export const DEFAULT_BRAND_COLOR = '#4F63FF';

//******************************************************************************
// Commands
//******************************************************************************

/**
 * A user invite is automatically accepted when an invite is sent
 * to an existing chat-user or when an invite is sent to a new user
 * and the user signs up with the same email address as the invite.
 * So we do not need to make an direct request to the chat service
 * to accept an invite.
 *
 * Dev note: This constant is unused, but keep here as a reference
 * in case we change how we handle accepting invites in the future.
 */
export const COMMAND_INVITE_USER = 'invite_user';

export const COMMAND_HANDLE_NOTIFICATION = 'handle_notification';
export const COMMAND_LOGOUT = 'logout';
export const COMMAND_NAVIGATE_INTRO = 'navigate_intro';
export const COMMAND_NAVIGATE_CHANNEL_LIST = 'navigate_channel_list';
export const COMMAND_NAVIGATE_GROUP_CHANNEL = 'navigate_group_channel';

/**
 * An admin invite is automatically accepted when an invite is sent
 * to an existing chat-user or when an invite is sent to a new user
 * and the user signs up with the same email address as the invite.
 * So we do not need to make an direct request to the chat service
 * to accept an invite.
 *
 * Dev note: This constant is unused, but keep here as a reference
 * in case we change how we handle accepting invites in the future.
 */
export const COMMAND_INVITE_CHANNEL_ADMIN = 'invite_channel_admin';

/**
 * The session is already refreshed when resolveChatUserThunk is called,
 * and this is called for every module command, so we do not need to
 * do anything else here to refresh the session.
 *
 * Dev note: This constant is unused, but keep here as a reference
 * in case we change how we handle session refresh in the future.
 */
export const COMMAND_REFRESH_CHAT_SESSION = 'refresh_chat_session';

export const COMMAND_SET_TOKEN = 'set_token';

//******************************************************************************
// Navigation
//******************************************************************************
export const MAIN_STACK = 'main_stack';
export const ROOT_SCREEN = 'root_screen';
export const APP_STACK = 'app_stack';
export const INTRO_SCREEN = 'intro_screen';
export const BLOCKED_USER_LIST_SCREEN = 'blocked_user_list_screen';
export const CHANNEL_LIST_SCREEN = 'channel_list_screen';
export const CHAT_SCREEN = 'chat_screen';
export const CHAT_THREAD_SCREEN = 'Thread';
export const CHAT_SETTINGS_MODAL = 'chat_settings_modal';
export const CHAT_SETTINGS_SCREEN = 'chat_settings_screen';
export const ACCOUNT_SCREEN = 'account_screen';
export const DISCOVER_DETAIL_SCREEN = 'discover_detail_screen';
export const INVITE_SCREEN = 'invite_screen';
export const MEMBER_LIST_SCREEN = 'member_list_screen';
export const CREATE_CHANNEL_MODAL = 'create_channel_screen';
export const CREATE_DIRECT_MODAL = 'create_direct_screen';
export const CHANNEL_DETAILS_MODAL = 'channel_details_modal';
export const CHANNEL_DETAILS_SCREEN = 'channel_details_screen';
export const EDIT_CHANNEL_SCREEN = 'edit_channel_screen';
export const EVENT_DETAILS_SCREEN = 'event_details_screen';
export const GIF_SCREEN = 'gif_screen';
export const GROUPS_SCREEN = 'Search';
export const GROUPS_DETAIL = 'Result';
export const POLL_SCREEN = 'poll_screen';
export const POLL_RESULTS_MODAL = 'poll_results_modal';
export const PRAYER_REQUEST_SCREEN = 'prayer_request_screen';
export const PRAYER_LIST_SCREEN = 'prayer_list_screen';
export const MEDIA_SELECTION_SCREEN = 'media_selection_screen';
export const MEDIA_DETAIL_SCREEN = 'media_detail_screen';
export const ACCEPT_INVITATION_SCREEN = 'accept_invitation_screen';
export const NOT_FOUND_SCREEN = 'not_found_screen';
export const BIBLE_LOCATION_SCREEN = 'bible_location_screen';
export const CREATE_GROUP_EVENT_SCREEN = 'create_group_event';
export const BIBLE_VERSE_SCREEN = 'bible_verse_screen';
export const EMPTY_SCREEN = 'empty_screen';
export const JOIN_REQUESTS_SCREEN = 'join_requests_screen';
export const VISIBILITY_AND_ENROLLMENT_SCREEN =
  'visibility_and_enrollment_screen';
export const ADD_MEETING_LOCATION = 'add_meeting_location';
export const ADD_MEETING_SCHEDULE = 'add_meeting_schedule';
export const GROUP_EVENTS_LIST_SCREEN = 'group_events_list';
export const GROUP_EVENT_REPEAT = 'group_event_repeat';
//******************************************************************************
// Web Screens
//******************************************************************************
export const PRAYER_TAB = 'Prayer';
export const CHAT_TAB = 'Chat';
export const THREADS_TAB = 'Threads';
export const CONVERSATIONS_TAB = 'Conversations';
export const DISCOVER_TAB = 'discover_screen';

//******************************************************************************
// API
//******************************************************************************
