import { KitIcon, KitText } from '@omni/kit/components';
import { IconName } from '@omni/kit/components/KitIcon';
import KitImage from '@omni/kit/components/KitImage';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { FileMessage, UserMessage } from '@sendbird/chat/message';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TextStyle, View } from 'react-native';

import { SendbirdMessage, SendbirdSenderMessage } from '../../../../Types';
import ParsedMessage from '../../../../mobile/components/chat/ParsedMessage';
import { ChatContext } from '../../../../mobile/scenes/channel/ChatScreen';
import { MessageType } from '../../../redux/types';

interface ParsedTextItem {
  pattern: RegExp;
  style: TextStyle;
  onPress: () => void;
}

interface ReplyViewProps {
  msg: SendbirdMessage;
  parsedTextObjects: ParsedTextItem[];
}

export default function ReplyView({
  parsedTextObjects = [],
}: ReplyViewProps): JSX.Element {
  const { repliedMessage } = useContext(ChatContext);
  const data = repliedMessage?.data
    ? JSON.parse(repliedMessage?.data)
    : undefined;
  const { t } = useTranslation();
  const renderIcon = () => {
    switch (repliedMessage?.customType) {
      case MessageType.Text:
        return null;
      case MessageType.Prayer:
        return <Icon name='prayer' />;
      case MessageType.Photo:
        return (
          <View
            style={{
              height: 16,
              width: 20,
              borderRadius: 2,
              overflow: 'hidden',
              marginRight: Spacing.xs,
            }}
          >
            <KitImage
              source={{ uri: (repliedMessage as FileMessage).url }}
              style={{ height: 16 }}
            />
          </View>
        );
      case MessageType.Gif:
        return (
          <View
            style={{
              height: 16,
              width: 20,
              borderRadius: 2,
              overflow: 'hidden',
              marginRight: Spacing.xs,
            }}
          >
            <KitImage source={{ uri: data?.uri }} style={{ height: 16 }} />
          </View>
        );
      case MessageType.File:
        return <Icon name='file' />;
      case MessageType.Poll:
        return <Icon name='poll' />;
      case MessageType.Media:
        return <Icon name='media' />;
      case MessageType.Bible:
        return <Icon name='bible' />;
      case MessageType.GroupEvent:
        return <Icon name='icons-event' />;
      default:
        return null;
    }
  };

  const Icon = ({ name, size }: { name: IconName; size?: number }) => (
    <KitIcon
      name={name}
      color={Colors.N500}
      size={size ? size : 14}
      style={{ marginRight: Spacing.s }}
    />
  );

  const label = () => {
    switch (repliedMessage?.customType) {
      case MessageType.Text:
        return (
          <ParsedMessage
            // @ts-ignore
            numberOfLines={2}
            color={Colors.N900}
            repliedMessage={repliedMessage}
            parsed={parsedTextObjects}
            style={{
              fontSize: 16,
              marginTop: 0,
            }}
          >
            {(repliedMessage as UserMessage).message}
          </ParsedMessage>
        );
      case MessageType.Prayer:
        return (repliedMessage as UserMessage).message;
      case MessageType.Photo:
        return 'Image';
      case MessageType.Gif:
        return 'GIF';
      case MessageType.File:
        return (repliedMessage as FileMessage).name || 'Attachment';
      case MessageType.Poll:
        return (repliedMessage as UserMessage).message || 'Poll';
      case MessageType.Media:
        const title = data?.title;

        return title || 'Media';
      case MessageType.Bible:
        const reference = data?.displayReference;

        return reference || 'Passage';
      case MessageType.GroupEvent:
        const groupEventTitle = data.groupEvent.title;

        return groupEventTitle || t('messaging:groupEventTitle');
      default:
        return null;
    }
  };

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      {renderIcon()}
      <KitText numberOfLines={2} fontSize={12} expandable>
        {label()}
      </KitText>
    </View>
  );
}
