import { KitText } from '@omni/kit/components';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitTouchableOption from '@omni/kit/components/KitTouchableOption';
import Show from '@omni/kit/components/Show';
import Spacing from '@omni/kit/theming/Spacing';
import { createImageUrl } from '@omni/kit/utilities/utilities';
import React from 'react';
import { View } from 'react-native';

type Option = {
  id: string;
  title: string;
  subtitle?: string;
  avatar?: string;
};

type Props = {
  options: Option[];
  selectedOption: number | null;
  optionHeight?: number;
  fontSize?: number;
  dynamicBoldStyle?: boolean;
  row?: boolean;
  onSelectOption: (index: number) => void;
  rowMargin?: number;
};

export default function KitOptionSelector({
  options,
  selectedOption,
  optionHeight,
  fontSize,
  dynamicBoldStyle,
  row,
  onSelectOption,
  rowMargin,
}: Props): JSX.Element {
  return (
    <View
      style={
        row
          ? {
              flexDirection: 'row',
              justifyContent: 'space-between',
            }
          : {}
      }
    >
      {options.map((option, index) => (
        <KitTouchableOption
          key={index}
          id={option.id}
          onSelect={() => onSelectOption(index)}
          isSelected={selectedOption === index}
          option={option}
          style={
            row
              ? {
                  flex: 1,
                  marginLeft: index > 0 ? Spacing.l : 0,
                }
              : {
                  marginTop:
                    index > 0
                      ? rowMargin !== undefined
                        ? rowMargin
                        : Spacing.l
                      : 0,
                }
          }
        >
          <View
            style={{
              alignItems: 'center',
              paddingHorizontal: Spacing.l,
              height: optionHeight,
              flexDirection: 'row',
            }}
          >
            {option.avatar && (
              <KitAvatar
                style={{ marginRight: Spacing.m }}
                size={44}
                imageUrl={createImageUrl(option.avatar)}
                nickname={option.title}
              />
            )}
            <View style={{ flexDirection: 'column' }}>
              <KitText
                black
                bold={dynamicBoldStyle ? selectedOption === index : true}
                fontSize={fontSize}
              >
                {option.title}
              </KitText>
              <Show show={Boolean(option.subtitle)}>
                <KitText style={{ marginTop: Spacing.xs }} fontSize={fontSize}>
                  {option.subtitle}
                </KitText>
              </Show>
            </View>
          </View>
        </KitTouchableOption>
      ))}
    </View>
  );
}
