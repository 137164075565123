import { KitIcon, KitText, KitTouchable } from '@omni/kit/components';
import Tooltip from '@omni/kit/components/Tooltip';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { BaseMessage, UserMessage } from '@sendbird/chat/message';
import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Clipboard, Pressable, StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { PopupActions } from 'reactjs-popup/dist/types';

import { SendbirdFileMessage, SendbirdUserMessage } from '../../../../Types';
import ActionSheetItem from '../../../../mobile/components/chat/actionSheet/ActionSheetItem';
import { ChatContext } from '../../../../mobile/scenes/channel/ChatScreen';
import { sbToggleReaction } from '../../../../utilities/sendbird/chatFunctions';
import { updateMessageType } from '../../../redux/actions/ChatActions';
import { channelSelector, userIdSelector } from '../../../redux/selectors';
import { MessageType } from '../../../redux/types';

const reactions = ['👍', '❤️', '😄', '🙏', '🎉', '😂'];

export default function MessageWrapper({
  message,
  disabled,
  openThreadScreen,
  children,
}: {
  message: BaseMessage;
  disabled: boolean;
  openThreadScreen: () => void;
  children: ReactNode;
}): JSX.Element {
  const [messageIsHovered, setMessageIsHovered] = useState<boolean>(false);

  const hoverMessage = () => setMessageIsHovered(true);
  const unhoverMessage = () => setMessageIsHovered(false);

  return (
    <div onMouseEnter={hoverMessage} onMouseLeave={unhoverMessage}>
      <View>
        {!disabled && (
          <HoverButtons
            isMessageHovered={messageIsHovered}
            message={message}
            openThreadScreen={openThreadScreen}
          />
        )}
        <View
          style={{
            backgroundColor:
              messageIsHovered && !disabled ? Colors.N50 : 'transparent',
            zIndex: 2,
          }}
        >
          {children}
        </View>
      </View>
    </div>
  );
}

// @ts-ignore
function HoverButtons({ isMessageHovered, message, openThreadScreen }) {
  const [hoveredIcon, setHoveredIcon] = useState<string>('');

  const channel = useSelector(channelSelector);
  const userId = useSelector(userIdSelector);

  const emojiRef = useRef<PopupActions | null>(null);
  const menuRef = useRef<PopupActions | null>(null);

  const dispatch = useDispatch();
  const {
    setIsEditing,
    setActiveMessage,
    setTextMessage,
    deleteMessage,
    setIsAnsweringPrayer,
    reportMessage,
  } = useContext(ChatContext);

  const _copyMessage = () => {
    Clipboard.setString((message as UserMessage).message);
  };

  const _editMessage = () => {
    setIsEditing?.(true);
    setActiveMessage?.(message as SendbirdUserMessage);
    setTextMessage?.((message as SendbirdUserMessage).message);
    menuRef.current?.close();
  };

  const _deleteMessage = () => {
    setActiveMessage?.(message as SendbirdUserMessage);
    deleteMessage?.();
    menuRef.current?.close();
  };

  const _reportMessage = () => {
    setActiveMessage?.(message as SendbirdUserMessage);
    reportMessage?.();
    menuRef.current?.close();
  };

  const _answerPrayer = () => {
    menuRef.current?.close();
    setActiveMessage?.(message as SendbirdUserMessage);
    setIsAnsweringPrayer?.(true);
  };

  const _convertToPrayerRequest = () => {
    dispatch(
      updateMessageType(
        channel as GroupChannel,
        message?.messageId,
        MessageType.Prayer
      )
    );
    setActiveMessage?.(null);
    menuRef.current?.close();
  };

  const toggleReaction = (emojiKey: string) => {
    emojiRef.current?.close();
    sbToggleReaction(
      channel as GroupChannel,
      message as SendbirdUserMessage,
      emojiKey,
      userId as string
    );
  };

  useEffect(() => {
    if (!isMessageHovered) {
      emojiRef.current?.close();
      if (showMenu) {
        menuRef.current?.close();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessageHovered]);

  const showAnswerPrayer =
    message?.isUser &&
    message.customType === MessageType.Prayer &&
    !message.data?.isAnswered;

  const showCopy =
    message?.customType === MessageType.Text ||
    message.customType === MessageType.Prayer;
  const showEdit = message?.isUser && message?.customType === MessageType.Text;
  const showDelete = message?.isUser || channel?.myRole === 'operator';
  const showConvertPrayer =
    message.isUser && message.customType === MessageType.Text;
  const showReply = !message.isAdminMessage();
  const isAnnouncement = message?.messageType === 'admin';
  const showReport =
    !message.isUser &&
    !isAnnouncement &&
    message?.customType !== MessageType.GroupEvent;

  const showMenu =
    showAnswerPrayer ||
    showCopy ||
    showEdit ||
    showDelete ||
    showConvertPrayer ||
    showReport;

  return (
    <div
      style={{
        height: 36,
        alignSelf: 'flex-end',
        marginBottom: -19,
        marginRight: Spacing.l,
        zIndex: 3,
        opacity: isMessageHovered ? 1 : 0,
      }}
    >
      <View style={[styles.hoverIconWrapper, {}]}>
        {/* Emoji Icon */}
        <Popup
          ref={emojiRef}
          position='bottom right'
          on='click'
          arrow={false}
          trigger={
            <button
              style={{
                borderWidth: 0,
                backgroundColor: 'transparent',
                cursor: 'pointer',
                padding: 0,
              }}
            >
              <Tooltip label='Add reaction'>
                <div
                  style={divStyles(hoveredIcon === 'emoji').hoverIcon}
                  onMouseEnter={() => setHoveredIcon('emoji')}
                  onMouseLeave={() => setHoveredIcon('')}
                >
                  <KitIcon name='emoji-add' size={18} color={Colors.N900} />
                </div>
              </Tooltip>
            </button>
          }
        >
          <div>
            <View style={[styles.popupWrapper, styles.emojiWrapper]}>
              {reactions.map((key) => (
                <KitTouchable
                  key={key}
                  onPress={() => toggleReaction(key)}
                  style={{
                    borderRadius: 80,
                    width: 44,
                    height: 44,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  borderRadius={80}
                >
                  <KitText fontSize={22}>{key}</KitText>
                </KitTouchable>
              ))}
            </View>
          </div>
        </Popup>

        {/* Reply Icon */}
        {showReply && (
          <Tooltip label='Reply'>
            <Pressable onPress={openThreadScreen}>
              <div
                style={divStyles(hoveredIcon === 'reply').hoverIcon}
                onMouseEnter={() => setHoveredIcon('reply')}
                onMouseLeave={() => setHoveredIcon('')}
              >
                <KitIcon name='reply' size={16} color={Colors.N900} />
              </div>
            </Pressable>
          </Tooltip>
        )}

        {/* More Actions */}
        {showMenu && (
          <Popup
            ref={menuRef}
            position='bottom right'
            on='click'
            arrow={false}
            trigger={
              <button
                style={{
                  borderWidth: 0,
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                  padding: 0,
                }}
              >
                <Tooltip label='More actions' position='top right'>
                  <div
                    style={divStyles(hoveredIcon === 'action').hoverIcon}
                    onMouseEnter={() => setHoveredIcon('action')}
                    onMouseLeave={() => setHoveredIcon('')}
                  >
                    <KitIcon name='action-h' size={18} color={Colors.N900} />
                  </div>
                </Tooltip>
              </button>
            }
          >
            <div>
              <View
                style={[
                  styles.popupWrapper,
                  { paddingVertical: Spacing.s, paddingHorizontal: 0 },
                ]}
              >
                {showAnswerPrayer && (
                  <ActionSheetItem
                    icon='prayer'
                    label='Mark as answered'
                    onPress={_answerPrayer}
                  />
                )}
                {showCopy && (
                  <ActionSheetItem
                    icon='copy'
                    label='Copy text'
                    onPress={_copyMessage}
                  />
                )}
                {showEdit && (
                  <ActionSheetItem
                    icon='edit'
                    label='Edit message'
                    onPress={_editMessage}
                  />
                )}
                {showDelete && (
                  <ActionSheetItem
                    icon='delete'
                    label='Delete message'
                    onPress={_deleteMessage}
                  />
                )}
                {showConvertPrayer && (
                  <ActionSheetItem
                    icon='prayer'
                    label='Convert to prayer request'
                    onPress={_convertToPrayerRequest}
                  />
                )}
                {showReport && (
                  <ActionSheetItem
                    icon='flag'
                    label='Report'
                    onPress={_reportMessage}
                  />
                )}
              </View>
            </div>
          </Popup>
        )}
      </View>
    </div>
  );
}

const styles = StyleSheet.create({
  hoverIconWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 60,
    borderWidth: 1,
    backgroundColor: Colors.N0,
    borderColor: 'rgba(0,0,0,.04)',
    shadowOpacity: 0.04,
    shadowRadius: 3,
    shadowOffset: {
      width: 1,
      height: 3,
    },
    shadowColor: Colors.N1000,
    padding: 3,
  },
  popupWrapper: {
    backgroundColor: Colors.N0,
    paddingHorizontal: Spacing.l,
    borderRadius: 14,
    shadowColor: '#000000',
    shadowOpacity: 0.06,
    shadowOffset: { width: 0, height: 3 },
    shadowRadius: 6,
  },
  emojiWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const divStyles = (isHovered: boolean) => ({
  hoverIcon: {
    width: 30,
    height: 30,
    borderRadius: 60,
    backgroundColor: isHovered ? Colors.N50 : 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
