import { ENV } from '@omni/kit/Environment';
import { KitIcon, KitModal, KitSnack, KitText } from '@omni/kit/components';
import { IconName } from '@omni/kit/components/KitIcon';
import { KitSnackDuration } from '@omni/kit/components/KitSnack';
import { useShellContext } from '@omni/kit/contexts/ShellContext';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import {
  bytesPerMb,
  fileTooLargeMessage,
  maxFileSizeInMb,
} from '@omni/kit/utilities/utilities';
import { logEvent } from '@omni/kit/utilities/utilities';
import { useNavigation } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Keyboard,
  Pressable,
  Image as RNImage,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import DocumentPicker from 'react-native-document-picker';
import { FlatList } from 'react-native-gesture-handler';
import ImagePicker, {
  Image as ImagePickerImage,
  Options as ImagePickerOptions,
} from 'react-native-image-crop-picker';
import { useDispatch, useSelector } from 'react-redux';

import * as Constants from '../../../shared/Constants';
import { sendFileMessage } from '../../../shared/redux/actions/ChatActions';
import { channelSelector } from '../../../shared/redux/selectors';
import { IFile, MessageType } from '../../../shared/redux/types';
import { processImageFileNative } from '../../../utilities/imageUtilities';

const debug = require('debug')('omni:chat:components:MessageTypeActionSheet');

interface MessageTypeButtonProps {
  icon: IconName;
  label: string;
  onPress?: () => void;
  style?: ViewStyle;
}

const MessageTypeButton: React.FC<MessageTypeButtonProps> = ({
  icon,
  label,
  onPress,
  style,
}) => (
  <Pressable onPress={onPress} style={style}>
    {({ pressed }) => (
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <View
          style={[
            styles.messageTypeButton,
            pressed && {
              backgroundColor: Colors.N200,
              transform: [{ scale: 0.96 }],
            },
          ]}
        >
          <KitIcon name={icon} size={20} color={Colors.N900} />
        </View>
        <KitText style={styles.messageTypeButtonText} color={Colors.N800}>
          {label}
        </KitText>
      </View>
    )}
  </Pressable>
);

interface MessageTypeActionSheetProps {
  onMessageSent?: () => void;
  isVisible?: boolean;
  setVisible: (visible: boolean) => void;
}

const MessageTypeActionSheet: React.FC<MessageTypeActionSheetProps> = ({
  isVisible,
  onMessageSent = () => undefined,
  setVisible,
}) => {
  const channel = useSelector(channelSelector);

  const dispatch = useDispatch();
  const navigation = useNavigation();

  const { t } = useTranslation(['common', 'messaging']);

  const _sendFileMessage = async (file: any, ratio?: number) => {
    const imageFile = await processImageFileNative(file, ratio);

    if (channel) dispatch(sendFileMessage(channel, imageFile));

    setVisible(false);
    onMessageSent();
  };

  const _onGifsPress = () => {
    setVisible(false);
    Keyboard.dismiss();
    setTimeout(() => {
      navigation.navigate(Constants.GIF_SCREEN, { channel });
    }, 500);
    onMessageSent();
  };

  const _onPrayerRequestPress = () => {
    setVisible(false);
    Keyboard.dismiss();
    setTimeout(() => {
      navigation.navigate(Constants.PRAYER_REQUEST_SCREEN);
    }, 500);
    onMessageSent();
  };

  const _onMediaPress = () => {
    setVisible(false);
    Keyboard.dismiss();
    setTimeout(() => {
      navigation.navigate(Constants.MEDIA_SELECTION_SCREEN);
    }, 500);
    onMessageSent();
  };

  const _onPollPress = () => {
    setVisible(false);
    Keyboard.dismiss();
    setTimeout(() => {
      navigation.navigate(Constants.POLL_SCREEN, { channel });
    }, 500);
    onMessageSent();
  };

  const _onBibleVersePress = () => {
    setVisible(false);
    Keyboard.dismiss();
    setTimeout(() => {
      navigation.navigate(Constants.BIBLE_LOCATION_SCREEN, { channel });
    }, 500);
    onMessageSent();
  };

  const _onCreateGroupEvent = () => {
    setVisible(false);
    Keyboard.dismiss();
    const event: any = {
      title: 'test',
      customType: MessageType.GroupEvent,
    };
    setTimeout(() => {
      navigation.getParent()?.navigate('channel_details_modal', {
        screen: 'create_event',
      });
    }, 500);
    onMessageSent();
  };

  const _onBrowsePhotosPress = async () => {
    const options: ImagePickerOptions = { mediaType: 'photo' };
    ImagePicker.openPicker(options)
      .then((image) => {
        const imageObject = image as ImagePickerImage;

        if (imageObject.width !== 0 && imageObject.height !== 0) {
          const ratio = imageObject.width / imageObject.height;
          const file: IFile = {
            customType: MessageType.Photo,
            data: { ratio: `${ratio}` },
            name: imageObject.filename || 'file',
            type: imageObject.mime || 'unknown',
            uri: imageObject.path,
          };
          _sendFileMessage(file, ratio);
        }
      })
      .catch(() => undefined);
    onMessageSent?.();
  };

  const _onFilePress = async () => {
    try {
      const fileObject = await DocumentPicker.pickSingle({
        type: [DocumentPicker.types.allFiles, DocumentPicker.types.zip],
      });
      const fileSize = (fileObject.size ?? 0) / bytesPerMb; // in megabytes

      if (fileSize > maxFileSizeInMb) {
        KitSnack.show(fileTooLargeMessage, KitSnackDuration.SHORT);
        logEvent('chat_file_exceeds_limit');

        return;
      }

      if (fileObject.type?.startsWith('image/')) {
        try {
          await RNImage.getSize(fileObject.uri, (width, height) => {
            if (width !== 0 && height !== 0) {
              const ratio = width / height;
              const file: IFile = {
                uri: fileObject.uri,
                name: fileObject.name ? fileObject.name : 'file',
                type: fileObject.type ? fileObject.type : 'unknown',
                customType: MessageType.Photo,
                data: { ratio: `${ratio}` },
              };
              _sendFileMessage(fileObject, ratio);
            }
          });
        } catch (error) {
          debug(error);
        }
      } else {
        const file: IFile = {
          uri: fileObject.uri,
          name: fileObject.name ? fileObject.name : 'file',
          type: fileObject.type ? fileObject.type : 'unknown',
          customType: MessageType.File,
          data: {},
        };

        _sendFileMessage(file);
      }
    } catch (err) {
      if (DocumentPicker.isCancel(err)) {
        // User cancelled the picker, exit any dialogs or menus and move on
        setVisible(false);
      } else {
        throw err;
      }
    }
  };

  // Used for checking if is sunny city and dev env
  const { app } = useShellContext();
  const groupEventsEnabled = app.appKey === 'CHURCH' && ENV === 'dev';

  const messageButtonsData: MessageTypeButtonProps[] = useMemo(() => {
    const list = [
      {
        icon: 'image',
        label: t('common:photo'),
        onPress: _onBrowsePhotosPress,
      },
      { icon: 'gif', label: t('common:gif'), onPress: _onGifsPress },
      {
        icon: 'prayer',
        label: t('common:prayer'),
        onPress: _onPrayerRequestPress,
      },
      { icon: 'file', label: t('common:file'), onPress: _onFilePress },
      { icon: 'media', label: t('common:media'), onPress: _onMediaPress },
      { icon: 'poll', label: t('common:poll'), onPress: _onPollPress },
      {
        icon: 'bible',
        label: t('common:passage'),
        onPress: _onBibleVersePress,
      },
      ...(groupEventsEnabled
        ? [
            {
              icon: 'icons-event',
              label: t('messaging:groupEventTitle'),
              onPress: _onCreateGroupEvent,
            },
          ]
        : []),
    ] as MessageTypeButtonProps[];

    return list;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _renderButtonGridItem = ({
    item,
  }: {
    item: MessageTypeButtonProps;
  }) => (
    <View style={styles.buttonGridItem}>
      <MessageTypeButton {...item} />
    </View>
  );

  return (
    <KitModal visible={Boolean(isVisible)} setVisible={setVisible}>
      <View style={styles.actionSheetContainer}>
        <View style={styles.buttonsContainer}>
          <FlatList
            numColumns={3}
            data={messageButtonsData}
            showsVerticalScrollIndicator
            keyExtractor={(item) => item.label}
            renderItem={_renderButtonGridItem}
          />
        </View>
      </View>
    </KitModal>
  );
};

const styles = StyleSheet.create({
  actionSheetContainer: {
    paddingBottom: Spacing.xxl,
    paddingTop: Spacing.xl,
  },
  buttonGridItem: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: Spacing.l,
    marginHorizontal: Spacing.s,
    width: 101,
  },
  buttonsContainer: {
    alignItems: 'center',
  },
  imagesGridContainer: {
    flex: 1,
    marginBottom: Spacing.xl,
  },
  imageGridItem: {
    borderRadius: BorderRadius.s,
    height: 101,
    marginHorizontal: Spacing.s,
    marginVertical: Spacing.s,
  },
  imagePickerGridItem: {
    alignItems: 'center',
    backgroundColor: Colors.N200,
    justifyContent: 'center',
    width: 101,
  },
  messageTypeButton: {
    alignItems: 'center',
    backgroundColor: Colors.N100,
    borderRadius: 54,
    height: 54,
    justifyContent: 'center',
    width: 54,
  },
  messageTypeButtonText: {
    marginTop: Spacing.m,
    textAlign: 'center',
  },
});

export default MessageTypeActionSheet;
