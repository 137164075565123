import {
  KitButton,
  KitSelect,
  KitText,
  KitTouchable,
} from '@omni/kit/components';
import KitDatePickerInput from '@omni/kit/components/KitDatePickerInput/KitDatePickerInput';
import { DateTimePickerEvent } from '@omni/kit/components/KitDatePickerInput/types';
import KitOptionSelector from '@omni/kit/components/KitOptionSelector';
import { useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useSelector } from 'react-redux';

import { accessTokenSelector } from '../../redux/selectors';

interface Props {
  navigation: any;
  route: any;
  isOpenRepeatingEventModal: boolean;
  setIsOpenRepeatingEventModal: (open: boolean) => void;
}
type RefType = {
  closePicker: () => void;
};

export default function RepeatGroupEvent({
  route,
  navigation,
  isOpenRepeatingEventModal,
  setIsOpenRepeatingEventModal,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const accessToken = useSelector(accessTokenSelector);
  const [frequencyValue, setFrequencyValue] = useState('never');
  const isWeb = Platform.OS === 'web';
  const isIOS = Platform.OS === 'ios';
  const isAndroid = Platform.OS === 'android';
  const { windowWidth, windowHeight } = useSizeClass();
  const stopRepeatingDateRef = useRef<RefType>(null);
  const [selectedOption, setSelectedOption] = useState<number | null>(0);
  const { startDate } = route.params;
  const [stopEventDate, setStopEventDate] = useState<Date>(startDate);
  const isEdited = useMemo(() => {
    // TODO: Finish implementing when backend for group events is ready
    return frequencyValue !== 'never';
  }, [frequencyValue]);

  // Get the week number of started date
  const getWeekNumberInMonth = (date: Date): number => {
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const dayOfMonth = Math.floor(
      (date.getTime() - startOfMonth.getTime()) / 86400000 /* day in msec */
    );

    return Math.ceil((dayOfMonth + 1) / 7);
  };
  // Calculate the week number of start date
  const weekNumberInMonth = getWeekNumberInMonth(startDate);
  const isLandscape = windowHeight < windowWidth;
  const containerPadding = {
    padding: !isWeb && isLandscape ? Spacing.xl : 0,
    paddingHorizontal:
      (isIOS && !isLandscape) || isAndroid ? Spacing.l : isWeb ? 0 : Spacing.xl,
    paddingTop:
      (isIOS && !isLandscape) || isAndroid ? Spacing.m : isWeb ? 0 : Spacing.xl,
  };
  const options = [
    { id: '0', title: t('messaging:never') },
    { id: '1', title: t('messaging:onDate') },
  ];

  const onChangeStopEventDate = (
    _event?: DateTimePickerEvent,
    selectedDate?: Date
  ) => {
    const startDate = moment(selectedDate).startOf('day').toDate();
    setStopEventDate(startDate);
  };
  const handleSave = useCallback(() => {
    if (isEdited) {
      // TODO: finish implementing when backend is ready
      console.log('save');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdited, frequencyValue, accessToken, navigation]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      navigation.setOptions({
        headerRight: () => (
          <KitTouchable
            onPress={() => handleSave()}
            disabled={!isEdited}
            style={{
              paddingLeft: 12,
              paddingRight: Platform.OS === 'ios' ? 12 : 18,
              paddingVertical: 6,
              marginRight: -6,
              borderRadius: 40,
            }}
          >
            <KitText
              color={isEdited ? Colors.brand : Colors.N400}
              fontSize={16}
            >
              {t('common:buttonTitleSave')}
            </KitText>
          </KitTouchable>
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdited, handleSave, navigation]);

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        stopRepeatingDateRef?.current?.closePicker?.();
      }}
      accessible={false}
    >
      <View style={[styles.container, containerPadding]}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <KitText color={Colors.N900} style={styles.title}>
            {t('messaging:groupEventRepeatTitle')}
          </KitText>
        </View>
        <View style={styles.selectContainer}>
          <KitSelect
            items={[
              {
                value: 'never',
                label: t('messaging:never'),
              },
              {
                value: 'daily',
                label: t('groups:frequency_daily'),
              },
              {
                value: 'every_interval_week_on',
                label: t(
                  'groups:frequency_every_interval_week_on_selected_day_other',
                  {
                    count: 2,
                    weekday: moment(startDate).format('dddd'),
                  }
                ),
              },
              {
                value: 'monthly_on_selected_day',
                label: t('groups:frequency_monthly_on', {
                  weekNumberOrdinal: `${t('common:key', {
                    count: weekNumberInMonth,
                    ordinal: true,
                  })}`,
                  weekday: moment(startDate).format('dddd'),
                }),
              },
              {
                value: 'monthly_on_last_dayweek',
                label: t('groups:frequency_monthly_on_last_dayweek', {
                  weekday: moment(startDate).format('dddd'),
                }),
              },
              {
                value: 'annually_on_month_day',
                label: `${t('groups:frequency_annually_on_month_day', {
                  month: moment(startDate).format('MMMM'),
                  dayOrdinal: t('common:key', {
                    count: startDate.getDate(),
                    ordinal: true,
                  }),
                })}`,
              },
            ]}
            label={t('messaging:frequencyLabel')}
            labelStyle={{ marginBottom: 8 }}
            onValueChange={(value) => setFrequencyValue(value.value as string)}
            value={frequencyValue || 'never'}
          />
        </View>
        {frequencyValue !== 'never' && (
          <View style={styles.stopRepeatingContainer}>
            <KitText fontSize={14} color={Colors.N600}>
              {t('messaging:stopRepeatingTitle')}
            </KitText>
            <View style={styles.selectButtonsContainer}>
              <KitOptionSelector
                options={options}
                selectedOption={selectedOption}
                onSelectOption={setSelectedOption}
                row
                optionHeight={58}
                fontSize={16}
                dynamicBoldStyle
              />
              {selectedOption === 1 && (
                <View style={styles.dateContainer}>
                  <KitDatePickerInput
                    ref={stopRepeatingDateRef}
                    date={stopEventDate}
                    onChange={onChangeStopEventDate}
                    minimumDate={startDate}
                    label={t('messaging:date')}
                  />
                </View>
              )}
            </View>
          </View>
        )}

        {(Platform.OS === 'web' || isLandscape) && (
          <View style={[styles.buttonContainer]}>
            <KitButton
              secondary
              title={t('messaging:buttonTitleCancel')}
              onPress={
                Platform.OS === 'web'
                  ? () => setIsOpenRepeatingEventModal(false)
                  : navigation.goBack
              }
              style={styles.rightButton}
            />
            <KitButton
              testID='save_button'
              title={t('common:buttonTitleSave')}
              color={Colors.N900}
              disabled={!isEdited}
              onPress={handleSave}
              style={styles.rightButton}
            />
          </View>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.N0,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: Platform.OS === 'web' ? 24 : 18,
  },
  selectContainer: {
    marginTop: Platform.OS !== 'android' ? -5 : 0,
    marginBottom: Spacing.l,
    // @ts-ignore
    appearance: 'none',
    outlineStyle: 'none',
    width: '100%',
  },
  stopRepeatingContainer: {
    marginBottom: Spacing.l,
    // @ts-ignore
    appearance: 'none',
    outlineStyle: 'none',
    width: '100%',
  },
  selectButtonsContainer: {
    marginTop: Spacing.s,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  dateContainer: {
    marginTop: Spacing.l,
  },
  rightButton: {
    marginLeft: Spacing.s,
    width: 100,
  },
});
