import {
  MaterialTopTabBarOptions,
  createMaterialTopTabNavigator,
} from '@react-navigation/material-top-tabs';
import React from 'react';
import { Platform } from 'react-native';

import { SizeClass, useSizeClass } from '../../contexts/SizeClassContext';
import Colors from '../../theming/Colors';

const Tab = createMaterialTopTabNavigator();

/*
 @deprecated
 Use @omni/kit/KitTab instead
 */
export const KitTab = Tab.Screen;

interface KitTabProps {
  initialRouteName?: string;
  children: JSX.Element[];
  tabBarOptions?: MaterialTopTabBarOptions;
}

/*
 @deprecated
 Use @omni/kit/KitTab instead
 */
export const KitTabGroup = ({
  initialRouteName,
  children,
  tabBarOptions,
}: KitTabProps): JSX.Element => {
  const { sizeClass } = useSizeClass();

  return (
    <Tab.Navigator
      initialRouteName={initialRouteName}
      tabBarOptions={
        {
          ...defaultTabBarOptions(sizeClass !== SizeClass.Small),
          ...tabBarOptions,
        } as MaterialTopTabBarOptions
      }
      swipeEnabled={false}
      lazy
    >
      {children}
    </Tab.Navigator>
  );
};

const defaultTabBarOptions = (isLargeScreen: boolean) => ({
  activeTintColor: Colors.N900,
  inactiveTintColor: Colors.N400,
  pressColor: Colors.N200,
  labelStyle: {
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 'bold',
    alignSelf: 'center',
    fontFamily: Platform.OS === 'web' ? 'inherit' : undefined,
  },
  indicatorStyle: {
    height: 3,
    backgroundColor: Colors.N900,
  },
  style: {
    ...(isLargeScreen && { height: 65, justifyContent: 'center' }),

    /**
     * Fix issue where tabs remains visible after navigation on web
     * The zIndex defaults to 1 in the react-native-tab-view TabBar component
     * https://github.com/react-navigation/react-navigation/blob/5a8d0f65e0d118317417cf76749e6f28fc50c02a/packages/react-native-tab-view/src/TabBar.tsx#L699
     * which was causing the tabs to remain visible after navigation
     * and overlapping elements on various screens.
     */
    ...(Platform.OS === 'web' && { zIndex: 'auto' }),
  },
});
