import { useShellContext } from '@omni/kit';
import { KitText } from '@omni/kit/components';
import { EventImage } from '@omni/kit/components/EventImage';
import {
  WindowSizeMin,
  useSizeClass,
} from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { dateFormatRange } from '@omni/kit/utilities/dateFormatRange';
import {
  ImageServiceType,
  SCREEN_WIDTH,
  createImageUrl,
  parseImageUrl,
} from '@omni/kit/utilities/utilities';
import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Platform,
  Pressable,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';

import { CustomMessageData } from '../../../../../Types';
import { ChatContext } from '../../../../../mobile/scenes/channel/ChatScreen';

const debug = require('debug')(
  'omni:messaging:kit:scenes:channel:GroupEventMessage'
);

export default function GroupEventMessage({
  message,
}: {
  message: any; // to be changed later;
}): JSX.Element {
  const { t } = useTranslation();
  const { tokens } = useShellContext();
  const accessToken = useMemo(() => tokens.user, [tokens.user]);
  const data = message.data;
  let startDateInEvent, endDateInEvent, imageURL, eventTitle;
  const { smallestWindowWidth, windowWidth } = useSizeClass();
  const isSmall =
    Platform.OS === 'web'
      ? windowWidth < WindowSizeMin.L
      : smallestWindowWidth < WindowSizeMin.L;
  const { handleSelectingMessage } = useContext(ChatContext);

  if (data) {
    try {
      const parsedData: CustomMessageData = JSON.parse(data);
      startDateInEvent = parsedData.groupEvent?.start_at;
      endDateInEvent = parsedData.groupEvent?.end_at;
      imageURL = parsedData.groupEvent?._embedded?.images?.[0].id;
      eventTitle = parsedData.groupEvent?.title;
    } catch (e) {
      debug('Error parsing group event in event message');
    }
  }

  const mediaWidth = isSmall ? SCREEN_WIDTH * 0.75 : 400;
  const mediaHeight = mediaWidth / 1.6;
  const endDate = moment(endDateInEvent);
  const hasEnded = moment().isAfter(endDate);
  const imageUrl = parseImageUrl(
    createImageUrl(imageURL),
    mediaWidth,
    mediaHeight,
    ImageServiceType.ImageJpeg
  );

  return (
    <Pressable
      style={({ pressed }) => ({
        borderRadius: 20,
      })}
      onPress={() => console.log('go to event details')}
    >
      <View style={styles.container}>
        <View style={styles.line} />
        <View
          style={{
            flexDirection: 'column',
            marginLeft: Spacing.m,
          }}
        >
          <TouchableOpacity
            onLongPress={handleSelectingMessage}
            style={{ width: mediaWidth }}
          >
            <EventImage
              accessToken={accessToken}
              redirectUrl={imageUrl}
              date={startDateInEvent}
              customWidth={imageUrl ? mediaWidth : 36}
              customHeight={imageUrl ? mediaHeight : 36}
            />
            <KitText
              numberOfLines={1}
              ellipsizeMode='tail'
              style={{
                maxWidth: mediaWidth,
                marginTop: Spacing.m,
                fontSize: 16,
                color: Colors.N900,
              }}
            >
              {eventTitle}
            </KitText>
            <KitText style={styles.eventTime}>
              {dateFormatRange({
                startDate: startDateInEvent ? startDateInEvent : '',
                endDate: endDateInEvent,
              })}
            </KitText>

            {hasEnded && (
              <View style={styles.eventEnded}>
                <KitText fontSize={14} color={Colors.N500}>
                  {t('groups:endedEventText')}
                </KitText>
              </View>
            )}
          </TouchableOpacity>
        </View>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Spacing.xs,
    flex: 1,
    minHeight: 50,
  },
  line: {
    width: 3,
    height: '100%',
    position: 'absolute',
    borderRadius: 1.5,
    backgroundColor: Colors.N150,
  },
  image: {
    marginLeft: 5,
    width: 50,
    height: 50,
  },
  eventTime: {
    fontSize: 14,
    color: Colors.N500,
  },
  eventEnded: {
    width: '100%',
    height: 50,
    backgroundColor: Colors.N100,
    borderRadius: 8,
    paddingHorizontal: 18,
    paddingVertical: 15,
    marginTop: 12,
    marginRight: 12,
  },
});
